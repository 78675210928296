import "./VideoIcon.scss";
const VideoIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 299.99 225">
      <defs></defs>
      <g>
        <g>
          <rect
            className="cls-1"
            width="299.99"
            height="225"
            rx="54.29"
            ry="54.29"
          />
          <path
            className="cls-2"
            d="m245.96,0H54.03C24.19,0,0,24.19,0,54.03v116.94c0,29.84,24.19,54.03,54.03,54.03h191.93c29.84,0,54.03-24.19,54.03-54.03V54.03c0-29.84-24.19-54.03-54.03-54.03Zm36.19,173.07c0,20.05-16.25,36.3-36.3,36.3H53.87c-20.05,0-36.3-16.25-36.3-36.3V51.15c0-20.05,16.25-36.3,36.3-36.3h191.98c20.05,0,36.3,16.25,36.3,36.3v121.92Z"
          />
          <g id="Layer_1-2" data-name="Layer 1-2">
            <path
              className="cls-2"
              d="m130.82,67.69l61.65,35.63c4.31,2.27,5.58,7.01,2.83,10.57-.65.85-1.5,1.58-2.49,2.16l-61.45,35.14c-1.64,1.14-3.72,1.76-5.86,1.76-5.11.03-9.29-3.37-9.33-7.6v-71.42h0c-.07-4.21,4-7.66,9.08-7.72,2-.02,3.96.5,5.57,1.48h0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VideoIcon;
