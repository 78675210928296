import React from "react";
import "./Hall.scss";

function HallBg() {
  return (
    <section className="hall">
      <p className="hall__text">
        Обновената Църковната зала се дава под наем на заинтересовани групи и
        лица за чествания и обществени прояви.
      </p>
      <h3 className="hall__heading">
        За запитвания относно дата и час, за оглед и за резервации:
      </h3>
      <div className="hall__item">
        <p className="hall__name">Фред Минков</p>
        <p>
          Тел.{" "}
          <a className="hall__contact" href="tel:416-483-5366">
            416 483-5366
          </a>
        </p>
        <p>
          Е-мейл:
          <a
            className="hall__contact"
            href="mailto:ourholytrinitymbc@gmail.com"
            target="_blank"
          >
            ourholytrinitymbc@gmail.com
          </a>
        </p>
      </div>
      <h3 className="hall__heading">Данни за залата:</h3>
      <ul className="hall__list">
        <li className="hall__item">
          Площ 450 кв. м. (приблизително 4000 кв. фута) на главната зала с
          допълнителни 100 кв.м. (900 кв. фута) на сцената. Капацитет 120 места.
        </li>
        <li>
          Залата се преобразува бързо в зрителна зала за спектакли. Капацитет
          около 200 места.
        </li>
        <li>Височина до тавана 3.2 м.</li>
        <li>В съседство със залата е кухня с пълно ресторантско оборудване.</li>
        <li>Отделен мокър бюфет</li>
        <li>По желание може да се наеме и дворът на църквата.</li>
        <li>
          Паркингът на църквата е за около 30 коли. Близките улици предлагат
          допълнителен паркинг.
        </li>
        <li>Залата е климатизирана.</li>
        <li>
          Свободна за заявки целогодишно във вечерните часове през седмицата и
          от сутрин до късна вечер, Събота през целия ден и Неделя след 2 ч.
        </li>
        <li>
          Залата отговаря на стандартите и изискванията за обществени места на
          провинция Онтарио.
        </li>
      </ul>
      <p className="hall__text">
        Църквата не прави разлика при подбора на кандидати за наемане, но си
        запазва правото да ги разграничава по пригодност към общоприети критерии
        и условия.
      </p>
      
    </section>
  );
}

export default HallBg;
