export const ourFatherTitle = {
    en: "Our Father",
    bg: "Отче Наш"
}

export const ourFather = {
    en: ["Our Father, who art in heaven,",
        "hallowed be Thy name.",
        "Thy Kingdom come, Thy will be done,",
        "on earth as it is in heaven.",
        "Give us this day our daily bread;",
        "and forgive us our trespasses",
        "as we forgive those who trespass against us;",
        "and lead us not into temptation",
        "but deliver us from evil."],
    bg: ["Отче наш, Иже еси на небесех",
        "Да святится имя Твое,",
        "да приидет Царствие Твое,",
        "да будет воля Твоя,",
        "яко на небеси и на земли;",
        "Хлеб наш насущный даждь нам днесь",
        "и остави нам долги наша",
        "якоже и мы оставляем должником нашим",
        "и не введи нас во искушение",
        "но избави нас от лукаваго"],
}

export const symbolTitle = {
    en: "The Creed: The Symbol of Faith",
    bg: "Символ на Вярата"
}

export const symbol = {
    en: ["I believe in one God, the Father Almighty, ",
        "Maker of Heaven and Earth ",
        "and of all things visible and invisible.",
        "And in one Lord Jesus Christ, the Son of God,",
        "the only-begotten, begotten of the Father before all ages.",
        "Light of light; true God of true God; begotten, not made;",
        "of one essence with the Father, by Whom all things were made;",
        "Who for us men and for our salvation came down from Heaven,",
        "and was incarnate of the Holy Spirit and the Virgin Mary,",
        "and became man. And He was crucified for us under Pontius Pilate,",
        "and suffered, and was buried. And rose again on the thrid day,",
        "according to the Scriptures, and ascended into Heaven,",
        "and sits at the right hand of the Father;",
        "and He shall come again with glory to judge the living and the dead;",
        "And His Kingdom shall have no end.",
        "And in the Holy Spirit, the Lord, the Giver of Life,",
        "Who proceeds from the Father; Who with the Father and the Son together",
        "is worshipped and glorified; Who spoke by the prophets.",
        "In one Holy, Catholic, and Apostolic Church.",
        "I acknowledge one baptism for the remission of sins.",
        "I look for the resurrection of the dead and the life of the world to come.",
        "Amen."],
    bg: ["Вярвам в един Бог Отец, Вседържител, Творец на небето и земята,",
        "ина всичко видимо и невидимо.",
        "И в един Господ Иисус Христос, Сина Божий, Единородния,",
        "Който е роден от Отца преди всички векове:",
        "Светлина от Светлина, Бог истинен от Бог истинен,",
        "роден, несътворен, единосъщен с Отца,",
        "чрез Когото всичко е станало;",
        "Който заради нас човеците, и заради нашето спасение",
        "слезе от небесата и се въплъти от Дух Светий",
        "и Дева Мария и стана човек; и бе разпнат за нас при Понтия Пилата,",
        "и страда, и бе погребан; и възкръсна в третия ден, според Писанията;",
        "и се възнесе на небесата и седи отдясно на Отца;",
        "и пак ще дойде със слава да съди живи и мъртви и царството Му не ще има край.",
        "И в Духа Светаго, Господа, Животоворящия, Който от Отца изхожда,",
        "Комуто се покланяме и Го славим наравно с Отца и Сина,",
        "и Който е говорил чрез пророците.",
        "В едната света съборна и апостолска Църква.",
        "Изповядвам едно кръщение за опрощаване на греховете.",
        "Чакам възкресение на мъртвите и живот в бъдещия век.",
        "Амин!"],
};